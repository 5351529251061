
.pf-noplan {
    background: grey;
}
.pf-empty {
    background: rgba(0, 0, 255, 0.22)
}
.pf-red {
    background: rgba(255, 0, 0, 0.26);
}
.pf-orange {
    background: rgba(255, 255, 0, 0.32);
}

.pf-green {
    background: rgba(0, 128, 0, 0.23);
}
