.caretProfile {
    opacity: .4;
    width: 25px;
    margin-top: -3px;
    transition: all .4s ease;
    position: absolute;
}
.menuProfile:hover .caretProfile {
    opacity: 1;
}
.top-links {
    width: 100px;
}
.menuProfile {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding-right: 30px;
    min-width: 100px;
    position: relative;
    max-width: 150px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis ellipsis;

}
.debugLogs:hover {
    opacity: 1;
}
.debugLogs {
    opacity: .7;
}
.App {
    text-align: center;
}
.label2 {
    margin-right: 5px;
}
.tagDetails > div {
    margin-left: 10px;
}
.shadow:hover {
    box-shadow: 3px 3px 7px lightgrey;
    z-index: 1000!important;
}
.fbList2 {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.fbListD {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}
.label {
    text-decoration: none!important;
}
.shadow {
    transition: box-shadow .4s ease;
}
.label-default {
    color: grey;

}
.w100 {
    width: 100%;
}
.ellipse {
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
}
.h100 {
    max-height: 150px;
    overflow-y: auto;

}
.approveList button small {
    margin-top: -2px;
    display: block;
    font-size: 9px;
}
.logoContrib {
    position: absolute;
    left: -10px;
    top: 5px;
    background: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    width: 7px;
    height: 7px;
}
.actionsQ button {
    /* margin-right: 10px; */
    margin: 0 3px
}
.logoContrib[data-action="contribute"] {
    background: rgba(0, 128, 0, 0.6);
}
.recommended {
    transition: all .4s ease;
    box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.371);
}
.logoContrib[data-action="skip"] {
    background: rgba(128, 128, 128, 0.5);
}
.remCol [class^='col-'] {
    width: auto;
}
.logoContrib[data-action="decline"] {
    background: rgba(255, 0, 0, 0.6);
}
.qWrap {
    margin-top: -5px;
    padding-bottom: 10px;
    width: 100%;
    /* border-bottom: 1px solid rgba(128, 128, 128, 0.244); */
    margin-bottom: 10px;
}
.qWrap small {
    margin-right: 5px!important;
    /* opacity: .7; */
    font-size: 8px;
    display: inline-block;
}
.versionV {
    padding-right: 10px;
    margin-right: -10px;
    border-radius: 5px;
    padding-left: 5px;
    cursor: pointer;
    position: relative;
    /* margin-left: -15px;
    padding-left: 15px; */
}
.activeV {
    background: rgba(211, 211, 211, 0.799);
    box-shadow: 2px 2px 5px rgba(211, 211, 211, 0.467);
    transition: all .4s ease;
    
}
.btns-wrap .btn {
    margin-right: 10px;
}
.MuiAutocomplete-root {
    max-width: 100%;
}
.title-use-case {
    font-weight: bold;
    font-size: 24px;
    padding: 10px 0;
}
.arr-item:hover .drag-handle, .arr-item:hover .fa-close {
    opacity: .7;
}

.quest-table  {
    margin-top: 10px;
    width: 100%;
}
.w-md-editor-text-pre, .w-md-editor-text-input {
    /*color: white;*/
}
.quest-table td {
    padding: 5px;
    vertical-align: top;
    border: 1px solid lightgrey;
}
.padd0, .padd0 select {
    padding: 0!important;
}
.inter-details {
    padding-left: 20px;
    padding-bottom: 20px;
}
.interviews-group b {
    margin-top: 10px;
    font-weight: bold;
    display: block;
}
.periods-wrap span {
    padding: 10px 10px;
    display: inline-block;
    cursor: pointer;
}
.interview-item small {
    font-size: 10px;
}
.error-wrap {
    background: rgba(255, 0, 0, 0.207);
}
.questionsTagsList a {
    color: black;
    margin-bottom: 10px;
    display: block;
}
.contenteditable-input {
    font-size: 10px!important;
}
.contenteditable-input [contenteditable]{
    max-height: 150px;
    min-height: 30px;
    overflow: auto;
    max-width: 100%;
    display: block;
    border: 1px solid lightgrey;
}
.pointer {
    cursor: pointer;
}
.questions-wrap {
    /* max-height: 600px; */
    /* overflow: auto; */
}
.sort-questions {
    border-bottom: 1px solid rgb(128 128 128 / 15%);
    padding: 5px 0;
}
.close-icon {
    font-weight: bold;
    padding: 10px 5px;
    opacity: .1;
    transition: all .4s ease;
}
.close-icon:hover {
    opacity: 1;

}
.activeQuestion {
    border: 1px solid #d3d3d3;
    /*box-shadow: 3px 4px 6px 3px #8080804f;*/
}
.Mui-focused label {
    opacity: 0;
}
.arr-item .drag-handle, .arr-item .fa-close {
    transition: all .4s ease;
    opacity: .05;
}
.MuiInputBase-input.MuiOutlinedInput-input {
    box-shadow: none;
}
.MuiOutlinedInput-notchedOutline {
    border: 0!important;
    border-radius: 0!important;
    border-top: 0 solid transparent;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.comps-list-detailed .drag-handle,
.comps-list-detailed .fa-close {
    margin-top: 20px !important;
}

.delta-input, .fhours-input {
    width: 70px;
    margin: 0;
    margin-left: -3px;
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 0;
    padding: 3px;
    height: 20px;
    text-align: center;
}

.big-input:focus, .small-input:focus {
    border: none;
    border-bottom: 1px solid grey;
}

.big-input, .small-input {
    outline: none;
    border: none;
    border-bottom: 1px dashed #f5f5f5;
    width: 100%;
    font-size: 35px;
    text-align: left;
    padding: 25px 15px;
    margin-top: 5px;
    margin-bottom: 20px;
}
.small-input {
    font-size: 15px;
    padding: 10px 15px;
}

.customization {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.comp-vars.delta {
    margin-left: 15px;
}

.comp-img[data-key="delta"] {
    /*width: 15px;*/
    text-align: center;
    margin: 0 auto;
}

.comp-icons-block {
    margin-right: 5px;
}

.comp-img[data-key="fhours"] {
    /*width: 15px;*/
}

.w100 {
    width: 100%;
}

.comp-img {
    max-height: 100%;
    width: 20px;
}

.searcher-button {
    margin-top: 30px;
}

.child-non-selected .child-checkbox {
    /*padding-top: 7px;*/
}

.child-checkbox {
    /*padding-top: 12px;*/
    padding-right: 5px;
    margin-top: -10px;
    margin-left: 5px;
}

.cust-child {
    padding-top: 16px;
}

body {
    padding-left: 15px;
}

.small-title {
    font-size: 12px;
}

.child-name {
    /*padding-top: 16px;*/
}

.customization .cust-details {
    padding-left: 5px;
}

.passive:focus {
    opacity: 1;
}

.comp-vars, .passive {
    opacity: .1;
    transition: all .4s ease;
}

.passive {
    opacity: .4;
}

.comp-vars:hover {
    opacity: .5;
}

.comp-vars.active, .comp-vars.active:hover {
    /*color: #7a6fbe;*/
    text-decoration: underline;
    font-weight: bold;
    opacity: .7;
    transition: all .4s ease;
    /*background: #e5daf6;*/
}
.count-input {
    width: 30px!important;
    margin-left: 10px;
    margin-right: -15px;
}
.mt-10 {
    margin-top: 10px;
}

.sort-item {
    border-left: 4px solid transparent;
}

.sort-item.active {
    /*background: rgb(248, 246, 255);*/
    border-left: 4px solid black;
}

.custom-child {
    padding-left: 45px;
    width: 100%;
    /*position: relative;*/
}

/*.cust-details {*/
/*  display: block;*/
/*}*/
.preventClick::before {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.top-links a {
    margin-right: 15px;
}

.pub-hours {
    font-size: 25px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

/*iframe {*/
/*    width: 100%;*/
/*    height: 1000px;*/
/*}*/

.comp-vars {
    margin-right: 5px;
    cursor: pointer;
    /*font-weight: bold;*/
}

.hours {
    /*color: lightgrey;*/
    font-size: 12px;
    display: block;
    /*content: 'h';*/
    /*font-size: 10px;*/
}

.MuiAutocomplete-popper > * {
    z-index: 10000 !important;
    /*background: red;*/
}

body {
    background: #F5F5F5;
    font-size: 16px;
}

.mainCont, .headerCont {
    max-width: 1400px;
    margin: 0 auto;
}
.tc {
    text-align: center;
}
/*.np, .np > .col {*/
/*    padding: 0!important;*/
/*}*/
.no-padd {
    padding-top: 0 !important;
}

.main-score span {
    margin-right: 20px;
}

.main-score {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;

}

.back-link {
    padding-bottom: 15px;
    display: block;
}

.mainCont {

    background: #FFFFFF;
    box-shadow: -5px 1px 15px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 20px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.o3:hover {
    opacity: 1;
}
.o3 {
    opacity: .3;
}
.ib {
    display: inline-block;
    vertical-align: top;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.sep {
    border-bottom: 1px solid lightgrey;
    padding: 3px;
    margin: 3px 0;
}
.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mt-15 {
    margin-top: 15px;
}
.ml-15 {
    margin-left: 15px;
}
[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px!important;
}
.mr-5 {
    margin-right: 5px;
}