.historyWrap {
  height: 100px;
  width: 100%;
  background: #fefefe;
  vertical-align: bottom;
  overflow-x: auto;
	overflow-y: hidden;
}
.historyWrap > .active {
  border: 1px solid blue;
  height: 100%;
}

.adminValidation::before {
  content: 'For approve';
  font-size: 8px;
  padding: 1px 5px;
  background: green;
  margin-right: 5px;
  float: left;
}
.adminValidation:hover {
}
.historyWrap > div {

  border: 1px solid transparent;
  display: inline-block;
  width: 5px;
  /* margin-right: 2px; */
  height: 100%;
  position: relative;
  
}
.questions-wrap {
  padding: 3px 5px;
}
.historyWrap > div > div {
  background: green;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
}
.active {
  background: #ddf8dd;
}
.nav-link.active {
  background: rgba(0, 0, 0, 0.04);
}