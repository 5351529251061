.codeRunWrap {
    display: flex;
    height: calc(100vh - 46px);
    /* box-shadow: 0px -9px 9px 0px #ededed; */
    margin: 0;
    padding: 0!important;
    width: 100%;
}
[data-url^="/quiz/"].mainCont {
    border-radius: 0!important;
}
[data-url^="/quiz"] .codeRunWrap {
    height: calc(100vh - 45px)
}
[data-url="/run"].mainCont {
    ax-width: 100%;
    padding: 0;
    margin: 20px 15px;
    border-radius: 0!important;
}

.filesWrap {
    height: 20px;
    padding-left: 8px;

}

.filesItem {
    float: left;
    margin-right: 5px;
    padding: 1px 10px;
    cursor: pointer;
}

.item2 {
}

.cases-wrap {
    padding-bottom: 5px;
    /*position: absolute;*/
    /*width: 100%;*/
    /*background: white;*/
    /*margin-top: -2px;*/
    /*padding: 3px 0;*/
}

.code-run-tab-wrap {
    margin-top: -5px;
    margin-bottom: -10px;
}
.selectedFile {
    background: rgb(215 215 215 / 72%)!important
}
.code-run-tab.active {
    text-decoration: underline;
}

.code-run-tab {
    font-size: 12px;
    /* margin-top: 10px; */
    padding-top: 7px;
    display: inline-block;
    margin-bottom: -26px;
}

.item {
    /*width: 100%;*/
    /*height: 100%;*/
    /*height: 20000px;*/
}

.btn-reset {
    position: absolute;
    right: 0;
    z-index: 1;
}

.crTop {
    height: 36%;
    position: relative;
}

.vertChild {
    max-height: 100%;
    overflow: auto;
    padding: 10px;
    background: white;
}
.hiddenBlock {
    opacity: 0;
    /*visibility: hidden;*/
    pointer-events: none;
    display: none;
}
.crLeft {
    flex: 1;

    position: relative;
}

.crRight {
    position: relative;
    width: 60%;
    padding: 15px 0;
    padding-top: 0;

}

.dragging1, .dragging2 {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.dragging1 .resizeH, .dragging2 .resizeV, .resizeH:hover, .resizeV:hover {
    background:  var(--active-text-color);
}

.resizeH, .resizeV {
    background: #e5e5e5;
    position: absolute;
    z-index: 10000;
    transition: all .4s ease;
}

.mainTasksWrap {
    width: 100%;
    position: absolute;
    z-index: 100;
    padding: 10px;
    background: white;
    border-bottom: 1px solid #b4b4b4;
}
.dragging1 .logsRunWraps, .dragging2 .logsRunWraps {
    display: none;
}
.logsRunWraps {
    position: absolute;
    left: 0;
    top: 45px;
    height: calc(100% - 45px);
    overflow: hidden;
    width: 100%;
}

.codeRunWrap iframe {
    border: 0;
    border-top: 1px solid lightgrey;
    height: calc(100% - 25px)!important;
/**/
}

.logsRader {
    height: calc(100%);
}

.resizeH {
    height: 8px;
    width: 100%;
    left: 0;
    bottom: -8px;

}

.dragging1, .resizeH {
    cursor: ns-resize !important;
}

.dragging2, .resizeV {
    cursor: ew-resize !important;
}

.resizeV {
    width: 8px;
    height: 100%;
    right: -8px;
    top: 0;
    cursor: ew-resize;
}

.casesChild {
    /*height: calc(100% - 70px);*/
    padding-bottom: 50px;
    margin-top: 5px;
}

[status='ok'] .run-circle {
    background: green;
}

[status='error'] .run-circle {
    background: red;
}

.run-circle {
    width: 7px;
    height: 7px;
    background: lightgrey;
    display: inline-block;
    border-radius: 5px;
    margin-top: 7px;
    margin-left: -3px;
    margin-right: 5px;
    float: left;
}

.logs-preview > span:first-child::before {
    content: '';
}

.logs-preview > span::before {
    content: ', ';

}

.case-title {
    margin-right: 5px;
    padding: 3px 10px;
    cursor: pointer;
    border-radius: 5px;

}

.active-case {
    border: 1px solid darkblue !important;
}

.crVertWrap {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.crBot {
    flex: 1
}

.buttonsRun {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    background: lightgrey;
    text-align: right;
}