.historyItem {
    border-bottom: 1px solid rgba(128, 128, 128, 0.28);
    margin: 10px 0;
}

.rate_admin.selected {
    background: lightgrey;
}
.rate_admin {
    border: 1px solid grey;
    padding: 3px 7px;
    cursor: pointer;
}
.hashTitle small {
    font-size: 12px;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: block;
}
.hashTitle {
    /*border: 1px solid grey;*/
    margin: 0;
    padding: 0;
}