.ansSep::before {
    content: ' ';
    width: calc(100% - 20px);
    height: 20px;
    border-top: 1px dashed lightgrey;
    left: 10px;
    top: 0;
    position: absolute;
}
.ansSep {
    position: relative;
    padding-left: 20px;
    margin-right: -20px;
    padding-top: 5px;
    margin-top: 5px;
    max-width: 100%;
    box-sizing: border-box;
}
.parentFB {
    background: rgba(128, 128, 128, 0.08);
}
.tags {
    float: left;
    font-weight: bold;
}

.audio-grey {
    background: rgba(128, 128, 128, 0.2);
}
.audio-green {
    background: rgba(97, 229, 48, 0.1);
}
.audio-red {
    background: rgba(231, 73, 73, 0.1);
}
.audio-yellow {
    background: rgba(243, 197, 37, 0.1);
}