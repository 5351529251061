.errRec h1 {
    color: #c70101;
}
h1 {
    text-align: center;
    color: #7a6fbe;
    position: relative;
    z-index: 1;
    margin-top: 50px;
}

.animate .zoomChild {
    /*scale: 2;*/
    /*margin-top: 100px;*/
    transition: all 2s ease;
}

.zoomChild {
    scale: 1;
    position: absolute;
    margin-top: 0px;
    width: 80px;
    height: 80px;
    transition: all .4s ease;

}
.svgContainer.animate {
    height: 100px;
}
.svgContainer {
    position: relative;
    display: block;

    width: 80px;
    height: 80px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 20px;
    transition: all 1s ease, height 0s ease;


}

.svgContainer.animate .delay1 {
    animation: wavess 2.5s ease;
    animation-delay: 0.1s;
}
.svgContainer.animate .delay2 {
    animation: wavess 2.5s ease 0.7s infinite;
}
.svgContainer.animate .delay3 {
    animation: wavess 2.5s ease 1.3s forwards infinite;
}
.svgContainer.animate .delay4 {
    animation: wavess 2.5s ease 1.9s forwards infinite;
}

.animate .svg-box {
    border: none;
    box-shadow: none;
}
.svg-box {
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 8px 2px #7a6fbe;
}
.counting {
    opacity: 0;
    display: none;
}
.animate .counting {
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    margin-top: 8px;
    font-size: 20PX;
    display: block;
    animation: fade 1s ease 1s forwards;
}

.animate .svg-box img {
    animation: bloop 1s linear forwards;
}

.errRec .circle {
    background: rgba(199, 1, 1, 0.51);
}
.circle {
    display: block;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: #7a6fbe;
    transition: 5s ease;
    position: absolute;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInDeleay {
    0% {
        opacity: 0;
        transform: translateX(10px);
    }
    80% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes wavess {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
@keyframes bloop {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        margin-top: 20px;
        transform: scale3d(.7, .7, 1);
    } 100% {
        margin-top: 30px;
        transform: scale3d(.7, .7, 1);
    }
}