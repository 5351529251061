
.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.custom-checkbox-wrap label {
    padding-left: 0!important;
    margin-top: -5px;
}
/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active+label::before {
    background-color: #b4b4b4;
    border-color: #b4b4b4;
}

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem #b4b4b4;
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked)+label::before {
    border-color: #b4b4b4;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked+label::before {
    border-color: #626262;
    background-color: #626262;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled+label::before {
    background-color: #e9ecef;
}
