/*button {*/
/*  background-color: transparent;*/
/*  border: none;*/
/*  outline: none;*/
/*  cursor: pointer;*/
/*  transition: color .3s ease-in-out;*/
/*}*/
.star {
  font-size: 20px;
  cursor: pointer;
  padding: 5px 2px;
}
.on {
  color: #ff8000;
}
.off {
  color: #ccc;
}