

.quiz-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(128, 128, 128, 0.48);
  margin-right: 5px;
  transition: all 1s ease;
}
.quizResults,
.quizResults p {
  font-size: 14px !important;
}

.quizWrapRunExamChild {
  max-width: 600px;
  margin: 0 auto;
  padding: 15px;
  border: 1px solid #e0e0e0;
  text-align: left;
}
.quizWrapRunExam {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  height: auto;
  display: inline-block;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pt40 {
  wudth: 100%;
  padding-top: 40px;
}

.quiz-active {
  background: #7a6fbe;
}

.questionRunExam {
  padding: 10px;
  cursor: pointer;
}

.questionRunExam.active {
  background: lightgrey;
}

.quiz-passed {
  background: rgba(122, 111, 190, 0.68);
}

.status-title {
  font-weight: bold;
}

.selectorsWrap > .card-item {
  padding: 30px !important;
}

.quiz-status-q {
  cursor: pointer;
}

.card-item,
.quiz-status-q {
  margin: 6px 0;
  width: 100%;
  border: 1px solid rgb(232, 234, 237);
  padding: 12px 10px 12px 14px;
  box-shadow: 0px 2px 8px 0px rgba(77, 80, 99, 0.15);
  border-radius: 24px;
  color: #2a2e34;
  font-size: 14px;
  background: white;
}

.card-item {
  /* background: var(--quiz-background); */
  padding: 32px 24px;
  gap: 32px;
}

.quiz-title {
  max-width: 600px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  margin: 25px 0 45px;
}

.small .quiz-title {
  font-size: 16px;
  margin: 10px 0;
}

.variant-sep {
  border-bottom: 1px solid #f3f3f3;
  margin: 10px 0;
}

.fa-star {
  color: var(--perc-value);
}

tbody.error td,
.error {
  background: #fdefef !important;
}

tr:hover tbody.removed td,
tr:hover .removed {
  opacity: 1;
}

tbody.removed td,
.removed {
  opacity: 0.5;
  background: #d3d3f5 !important;
}

.fa-star-o {
  opacity: 0.1;
}

.temp-anwer-wrapper {
  /*background: #f3f3f3;*/
}

.hash-tags {
  display: block;
  margin-top: 30px;
}
.filesWrap {
  height: 20px;
  padding-left: 8px;
}
.filesItem {
  float: left;
  margin-right: 5px;
  padding: 1px 10px;
  cursor: pointer;
}

.hash-tags small {
  font-weight: 600;
  margin-right: 10px;
  border: 1px solid rgba(154, 154, 154, 0.31);
  border-radius: 10px;
  padding: 2px 10px;
  background: rgba(236, 236, 236, 0.9);
}

.answer-sep {
  border-top: 1px solid lightgrey;
  margin-top: -20px;
  padding-bottom: 20px;
}

.question-parent-group-wrap {
  padding: 10px;
}

@media (max-width: 900px) {
  .question-group-wrap {
    width: 100% !important;
  }

  /* … */
}

.question-group-wrap {
  word-break: break-word;
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
  vertical-align: top;
}

.selectorsWrap {
  margin-top: 20px;
}

.selectorsWrap > div {
  margin-right: 20px;
}

.tableStatuses small {
  font-weight: bold;
  border: 1px solid #b6b6b6;
  background: #e8e8e8;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 3px;
  font-size: 10px;
}

.ml15 {
  margin-left: 15px;
}

.filtersWrap .ib {
  margin-right: 10px;
}

.js-task-table {
  background: rgba(34, 186, 160, 0.05);
}

.tableStatuses {
  position: relative;
}

.quizPerc {
  width: 90%;
  height: 3px;
  background: #dadada;
  position: absolute;
  left: 5%;
  bottom: -7px;
}

.quizPercSize {
  min-width: 10px;
  background: var(--perc-value);
  height: 100%;
  left: 0;
  position: absolute;
}

.statusProgress {
  max-width: 250px;
  height: 3px;
  background: #dadada;
  width: 100%;
  position: relative;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.progressLine {
  min-width: 3px;
  background: var(--perc-value);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  border-radius: 5px;
}

[data-url^="/table"].mainCont {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin-top: -5px;
}

.MuiToolbar-root {
  padding: 5px !important;
}

.mainCont {
  border-radius: 5px !important;
}

/* body {
    background: #fafbfc !important;
    padding: 0 15px;
} */

.rel {
  position: relative;
}

.mr15 {
  margin-right: 15px;
}

.card-default-button-wrapper {
  display: flex;
}

/* .default-button {
  display: flex;
  padding: 9px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #d0d8ea;
  background: var(--white, #fff);
} */

.default-button-quiz,
.default-button-quiz:hover {
  color: white;
  background-color: var(--perc-value) !important;
}

.default-button-quiz:active,
.default-button-quiz:focus {
  background-color: #7a6fbe !important;
}
