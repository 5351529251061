.planing-page td {
  padding: 1px;
}
.tab-item {
  padding: 15px;
  display: inline-block;
}

.planing-page td:first-child {
  padding-left: 10px;
}
.planing-page tbody tr td:first-child {
  padding-left: 30px;
}
.planing-page tr {
  transition: all .4s ease;
}
.planing-page tr:hover {
  background: lightgrey;
  z-index: 10000!important;
  position: relative;
  box-shadow: 5px 5px 5px grey;
} 
.planing-page input {
  text-align: center;
  height: 28px;
}
.planing-page {
  width: 100%;
}
.plan-icons {
  width: 80px;
}
.plan-icons i {
  padding: 5px;
  display: inline-block;
}

.plan-wrap  small {
  font-size: 10px;
}