.circleGreen {
    background: #1DB198;
}
.circleOrange {
    background: #fcb926;
}
.circleBlue {
    background: #4b4b91;
}
.circleRed {
    background: red;
}
.circleEmpty {
    background: lightgrey;
}

.circleIndicator {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
}
.emptyCalendar {
    /*background: rgba(211, 211, 211, 0.7);*/
    opacity: .2
}
.no-select, .no-select * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.statsList {
    max-width: 150px;
    display: inline-block;
    padding: 10px;
    vertical-align: top;
}

.salesOpen {
    background: rgba(173, 216, 230, 0.5);
}