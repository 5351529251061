/*th:first-child, td:first-child*/
/*{*/
/*    position:sticky;*/
/*    left:0;*/
/*    background: rgb(249, 249, 249);*/
/*}*/
/*th:last-child, td:last-child*/
/*{*/
/*    position:fixed;*/
/*    background: rgb(249, 249, 249);*/
/*}*/
.stOk {
    cursor: pointer;
}
.graphIt {
    width: 220px;
    display: inline-block;
}
.stEmpty {
    opacity: .4;
}
.stNotSelected {
    opacity: .2;
}
.circle {
    background: grey;
    width: 5px;
    height: 5px;
    margin: 3px;
    border-radius: 50%;
    display: inline-block;
}
.passiveHours .passiveGraph{
    height: 8px;
    margin-top: 2px;
    width: 100%;
    background: rgba(189, 189, 189, 0.27);
}
.passiveHours .flex-bot-date {
    font-size: 10px;
}
.passiveHours {
    opacity: .4;
}
.activeHours {
    height: 100px;
}
.activeHours > div {
    margin-top: 2px;
    font-weight: bold;
}
.hoursWrap {
    margin-top: 2px;
}
.btn-day {
    margin-top: 20px;
    padding: 5px;
}
.statsGraph {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    gap: .5%;
}
.activeHours .flex-bot-stats {
    margin-top: 20px;
}
.activeHours .flex-bot-date {
    margin-top: 35px;
}
.flex{
    display: flex;
    flex-direction: column;
}
.emptyMinute {
    background: #f25656 !important;
}
.flex-bot {
    margin-top: auto;
}
.minutesWrap {
    margin-top: auto;
    display: inline-flex;
    gap: .5%;
    width: 100%;
    height: 60px;
    position: relative;
}
.statMinuteBlock {
    padding: 1px;
    box-sizing: border-box;
    margin-top: auto;
    background: #22BAA0;
    min-height: 3px!important;
    bottom: 0;
    position: absolute;
}