.tree-child {
    padding-left: 15px;
}
.trees .selected {
    background: rgba(58, 107, 128, 0.09);
}

.tree-title {
    font-weight: 500;
    padding: 3px 4px;
}

.arr-item {
    margin-top: -10px;
}
