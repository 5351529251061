
.my-btn-search {
    margin-top: -15px!important;
    padding: 10px 0;
}

th .fa-sort-amount-asc, th .fa-sort-amount-desc, th.desc .fa-sort, th.asc .fa-sort {
    display: none;
}

th.asc .fa-sort-amount-asc, th.desc .fa-sort-amount-desc {
    display: block;
    opacity: .5;
}
.th-name, .th-name .i {
    animation: none;
}

.btn-add {
    margin-top: -5px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background: #7a72bc;
    border-color: #7a72bc;
}
.tabsTitle {
    font-size: 30px;
}

.table-bordered, .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: .5px solid #dcdcdc;

}
table > tbody:nth-child(2n+1) td {
    background: white;
}
.btn-search {
    max-width: 300px;
    margin-top: 8px;
}


.opacity-loading-td td {
    opacity: .2;
    transition: all .4s ease;
}