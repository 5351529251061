.correctV {
    background: #e2f5e2;
}

.courseEditWrap:hover {
    background: #f3f3f3;
    box-shadow: 4px 4px 5px lightgrey;
}
.courseEditWrap {
    transition: all .4s ease;
    padding: 5px 0;

}